import {formatDateTimeToISOString, utcStringToDate, getTimeZone} from "@/shared/utils/dateUtils"

export class DetailedTimeStamp {
    localTime;
    utcTime;
    timeZone;
    constructor(localTime, utcTime, timeZone) {
        this.localTime = localTime;
        this.utcTime = utcTime;
        this.timeZone = timeZone;
    }

    static fromJson(json) {
        return new DetailedTimeStamp(json?.localTime, json?.utcTime, json?.timeZone);
    }

    static fromUTCTime(utcTime) {
        return new DetailedTimeStamp(
            formatDateTimeToISOString(new Date(utcTime)), 
            utcTime instanceof Date ? utcTime.toISOString() : utcTime, 
            getTimeZone());
    }
    
    getLocalTimeAsDate() {
        return new Date(this.localTime)
    }

    getUTCTimeAsDate() {
        return utcStringToDate(this.utcTime)
    }
}