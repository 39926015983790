<template>
    <DetailItemEditCard v-bind="$attrs" v-on="$listeners" :item-name="getDate" 
    :promises.sync="detailItemEditCardPromises" 
    :formPromises.sync="computedPromises" 
    :detailViewRouteLocation="getDetailViewRoute"
    >
      <template v-slot:form-fields>
        <slot name="form-fields"/>
      </template>
      
      <template v-slot:save-button-content>
        <slot name="save-button-content"/>
      </template>
    </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue"
import {applyDateFormat} from "@/shared/utils/dateUtils";
import routeNames from "@/router/RouteNames";

export default {
  name: "ClockingsDetailItemEditCard",
  props: {
    date: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: false,
      default: null
    },
    returnRoute: {
      type: Object,
      required: false,
      default:() => ({name: routeNames.MODIFY_USER_HOURS_DAY})
    },
    promises: {
      default: () => []
    }
  },
  emits: ["update:promises"],
  data() {
    return {
      detailItemEditCardPromises: [],
      routeNames
    }
  },
  methods: {
    applyDateFormat
  },
  inheritAttrs: false,
  components: {
    DetailItemEditCard,
  },
  computed: {
    getDate() {
      return this.applyDateFormat(this.date)
    },
    computedPromises: {
      get() {
        return this.promises
      },
      set(newValue) {
        this.$emit("update:promises", newValue)
      }
    },
    getDetailViewRoute() {
      if (this.userId) {
          return {name: this.returnRoute.name, params: {date: this.date, userId: this.userId}}
      } else {
        return { name: this.routeNames.DAY, params: { date: this.date, } }
      }
    }
  },
}
</script>