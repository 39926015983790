<template>
    <ClockingsDetailItemEditCard :confirmation-message="getConfirmationMessage"
                            title="Registratie"
                            :promises.sync="promises"
                            :date="getDay"
                            :detailViewRouteLocation="{name: routeNames.DAY, params: { date: getDay}}"
                            update-store-action="clockingsModule/createPricking"
                            :update-store-action-payload="getPricking"
                            :successRouteLocation="getSuccessRouteLocation"
                            :successCallback="getSuccessCallback"
                            :catchCallback="catchCallback"
                            @save="fetchData"
                            :overrideDisableButton="isEndPricking ? !isEndPricking : null">
        <template v-slot:form-fields>
            <WorkClockingForm v-model="workClocking" :hide-time-pickers="isStartPricking" :hide-comment="isStartPricking" :hide-break-time="isStartPricking" :disable-client-and-project-select="isEndPricking" :disable-time-pickers="true" :break-time="isEndPricking && !$store.state.clockingsModule.breakTime" :includeArchivedClientsAndProjects="includeArchivedClientsAndProjects"/>
        </template>
        <template v-slot:save-button-content>
            <template v-if="isEndPricking"><v-icon left>mdi-stop</v-icon>Stop</template>
            <template v-else><v-icon left>mdi-play</v-icon>Start</template>
        </template>
    </ClockingsDetailItemEditCard>
</template>

<script>
import ClockingsDetailItemEditCard from './calendar/forms/ClockingsDetailItemEditCard.vue';
import {format} from "date-fns";
import routeNames from "@/router/RouteNames";
import {applyDateFormat} from "@/shared/utils/dateUtils";
import ClockingType from "@/shared/constants/ClockingType";
import WorkClockingForm from '../components/registerHours/WorkClockingForm.vue';
import {organizationHasModule} from "@/shared/utils/authenticationUtils";
import ModuleType from "@/shared/enums/moduleType";
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp";

export default {
    name: "PrickingView",
    components: {
        ClockingsDetailItemEditCard,
        WorkClockingForm
    },
    data() {
        return {
            existingPricking: null,
            pricking: {
                timeStamp: new Date(),
                breakTime: false,
                clientId: null,
                projectId: null,
            },
            routeNames,
            promises: [
                this.fetchPricking()
                .then(() => this.promises.push(this.fetchDayOverview()))
            ],
            localStoragePrefix: "clocking#",
            roundTimeStampCoeff: 1000 * 60,
            workClocking: {
                id: this.clockingId,
                startTime: null,
                endTime: null,
                comment: null,
                breakTime: null,
                day: this.date,
                clockingType: ClockingType.TEMP_WORK,
            },
            clientProjectModuleInstalled: organizationHasModule([ModuleType.CLIENT_PROJECTMANAGEMENT])
        }
    },
    created() {
        this.startPrickingTimer()
        let basicDataPricking ={
          userId: this.$store.state.authModule.user.id,
          clockingType: ClockingType.TEMP_WORK,
          day: this.getDay,
          timeStamp: this.pricking.timeStamp
        }
        if (this.clientProjectModuleInstalled) {
          this.pricking = {
            ...this.pricking,
            clientId: localStorage.getItem(this.localStoragePrefix + "autoFillClientId"),
            projectId: localStorage.getItem(this.localStoragePrefix + "autoFillProjectId"),
            basicDataPricking
          }
        } else {
          this.pricking = {
            ...this.pricking,
            basicDataPricking
          }
        }
    },
    computed: {
        includeArchivedClientsAndProjects () {
            if (this.existingPricking == null) {
                return null
            }
            return this.isEndPricking
        },
        getDay() {
            return this.isEndPricking ? this.existingPricking.day : format(new Date(), 'yyyy-MM-dd')
        },
        getConfirmationMessage() {
            if (this.isEndPricking) {
                return "Registratie werd gestopt"
            } else {
                return "Registratie werd gestart"
            }
        },
        getPricking() {
          if (this.clientProjectModuleInstalled) {
            return {
              clientId: this.workClocking?.clientId,
              projectId: this.workClocking?.projectId,
              breakTime: this.isEndPricking ? this.workClocking?.breakTime : null,
              comment: this.isEndPricking ? this.workClocking?.comment : null,
            }
          }
          return {
            breakTime: this.isEndPricking ? this.workClocking?.breakTime : null,
            comment: this.isEndPricking ? this.existingPricking.comment : null,
          }
        },
        getSuccessRouteLocation() {
            if (this.isEndPricking) {
                return {name: routeNames.DAY, params: { date: this.getDay}}
            } else {
                return null
            }
        },
        getSuccessCallback() {
            if (this.isStartPricking) {
                return (data) => {
                    this.processPartialErrors(data)
                    this.promises.push(this.fetchPricking())
                }
            } else {
                return (data) => {
                    this.processPartialErrors(data)
                    this.$router.push(this.getSuccessRouteLocation)
                }
            }
        },
        isEndPricking() {
            return this.existingPricking && this.existingPricking.id != null
        },
        isStartPricking() {
            return !this.isEndPricking
        }
    },
    methods: {
        processPartialErrors(data) {
            if (data.errorMessages && data.errorMessages.length > 0) {
                this.$store.dispatch('setError', data.errorMessages.join("\n"))
            }
        },
        catchCallback() {
            this.existingPricking = null
            this.promises.push(this.fetchPricking())
        },
        fetchDayOverview() {
            return this.$store.dispatch('clockingsModule/fetchDayOverview', {
                date: this.getDay,
                userId: this.$store.state.authModule.user.id
            })
        },
        applyDateFormat,
        fetchData() {
            this.promises.push(this.fetchPricking())
        },
        startPrickingTimer() {
            setInterval(() => {
                this.pricking.timeStamp = new Date()
                this.workClocking.startTime = this.isEndPricking ? this.existingPricking.timeStamp : this.pricking?.timeStamp
                this.workClocking.endTime = this.isEndPricking ? this.pricking?.timeStamp : null
            }, 1000);
        },
        fetchPricking() {
            return this.$store.dispatch("clockingsModule/fetchPricking")
                .then(result => {
                    this.existingPricking = {
                        ...result, 
                        timeStamp: result?.timeStamp ? DetailedTimeStamp.fromJson(result?.timeStamp).getUTCTimeAsDate() : null
                    }
                    
                    this.setWorkClocking()
                })
        },
        setWorkClocking() {
          if(this.clientProjectModuleInstalled){
          this.workClocking = {
                ...this.pricking,
                clientId: this.isEndPricking ? this.existingPricking.clientId : this.pricking?.clientId,
                projectId: this.isEndPricking ? this.existingPricking.projectId : this.pricking?.projectId,
                startTime: this.isEndPricking ? this.existingPricking.timeStamp : this.pricking?.timeStamp,
                endTime: this.isEndPricking ? this.pricking?.timeStamp : null
            }
          } else {
            this.workClocking = {
              ...this.pricking,
              startTime: this.isEndPricking ? this.existingPricking.timeStamp : this.pricking?.timeStamp,
              endTime: this.isEndPricking ? this.pricking?.timeStamp : null
            }
          }
        }
    },
}
</script>